import Portfolio from "../assets/238-2384334_my-portfolio-cover-design-hd-png-download.png";
import Note from "../assets/note-taking.png";
import ChitChat from "../assets/aeba24b3dd009db57b5e87679f9a312b.jpg";

export const data = [
  {
    id: 1,
    name: "Portfolio",
    image: Portfolio,
    github: "https://github.com/Manan-Sharma-5/New-Portfolio",
    live: "https://manansharma.dev",
  },
  {
    id: 2,
    name: "Take Note",
    image: Note,
    github: "https://github.com/Manan-Sharma-5/Notes-App",
    live: "https://notes.manansharma.dev",
  },
  {
    id: 3,
    name: "Let's React",
    image: ChitChat,
    github: "https://github.com/Manan-Sharma-5/Let-s-React",
    live: "https://react.manansharma.dev",
  },
];
