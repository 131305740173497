import React, { useState } from 'react';
import me from '../assets/me.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Start = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div className="start mt-24 p-4">
      <div className="intro rounded-full">
        <img className="rounded-full" src={me} alt="Manan Sharma" />
      </div>
      <div className="Hello">
        <div className="line">
          <h1 className='text-4xl'>
            Hi, I am <span className="name font-bold text-5xl">Manan Sharma</span>👋
          </h1>
        </div>
        <br />
        <p>
          <h1 className='text-2xl'>Experienced Full Stack Developer crafting robust web solutions.</h1>
        </p>
        <div className="social-icons ">
        <a href="https://github.com/Manan-Sharma-5/" target="_blank">
          <FontAwesomeIcon icon={faGithub} />
        </a>
        <a href="https://www.linkedin.com/in/mash-sharma/" target='_blank'>
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
        <a href="https://twitter.com/Mash0riginal" target='_blank'>
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
        <button
          className="btn-hover color-2"
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
        ><a href="#projects">
          Projects{' '}
          </a>
          <FontAwesomeIcon icon={isHovered ? faArrowDown : faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default Start;
