import React from 'react'
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contact = () => {
  return (
    <div className='w-full h-5' id='contact'>
    <p className='contact-heading'>Contact</p>
    <div name='contact' className='flex justify-around p-4 contact-dir'>
    <div className="links flex flex-col">
    <p className='mb-5 font-bold text-2xl'>My Socials:</p>
    <div className="flex mt-2 contact-icons">
    <a href="https://github.com/Manan-Sharma-5/" target="_blank"><FontAwesomeIcon className='text-white text-3xl mx-2 cursor-pointer' icon={faGithub} /></a>
        <a href="https://www.linkedin.com/in/mash-sharma/" target='_blank'><FontAwesomeIcon className='text-white text-3xl mx-2 cursor-pointer' icon={faLinkedin} /></a>
        <a href="https://twitter.com/Mash0riginal" target='_blank'><FontAwesomeIcon className='text-white text-3xl mx-2 cursor-pointer' icon={faTwitter} /></a>
        </div>
        </div>
        <form method='POST' action="https://getform.io/f/b29516d2-82be-47e7-8066-6dd481acf8f4" className='flex flex-col max-w-[600px] text-black w-full'>
            <input className='bg-white p-4 outline-none rounded-2xl' type="text" placeholder='Name' name='name' />
            <input className='my-4 p-4 bg-white outline-none rounded-2xl' type="email" placeholder='Email' name='email' />
            <textarea className='bg-white p-4 outline-none rounded-2xl' name="message" rows="6" placeholder='Message'></textarea>
            <button className='text-white border-2 hover:bg-orange-500 duration-200 hover:border-yellow-300 px-4 py-3 my-8 mx-auto flex items-center'>Send</button>
        </form>
    </div>
            </div>
  )
}

export default Contact