import './App.css';
import Navbar from './components/Navbar';
import Start from './components/Start';
import Skills from './components/Skills';
import Background from './components/Background';
import Projects from './components/Projects';
import Contact from './components/Contact';
import About from './components/About';

function App() {

  return (
    <div>
    <Background />
      <Navbar />
      <Start />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
