import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div>
      {screenWidth > 768 ? (
        <div className="fixed top-0 left-0 w-full p-4 pt-0">
          <div className="nav bg-gray-800 text-white">
            <span className="banner mt-5">Manan Sharma</span>
            <ul className="flex nav-control">
              <li>
                {
                  // eslint-disable-next-line
                }
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#skills">Skills</a>
              </li>
              <li>
                <a href="#projects">Projects</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
              <li>
                <button className="bg-orange-500 text-white p-2 rounded-xl hover:bg-red-800 hover:text-white duration-200">
                  <a
                    href="https://drive.google.com/file/d/1o0nFCni8Ez62XcirVaOAxk0GWMXsOtBj/view?usp=drive_link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Resume
                  </a>
                </button>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="fixed top-0 left-0 w-full">
          <div className="nav bg-{#1b1b1b} text-white p-4">
            {!showNav && <span className="banner text-xl">Manan Sharma</span>}
            <div className=""></div>
            <div className="menu-icon top-0 text-2xl pr-3" onClick={toggleNav}>
              {showNav ? (
                <FontAwesomeIcon icon={faTimes} className="hidden" />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </div>
          </div>
          {showNav && (
            <div className="nav-menu text-white absolute inset-0 flex-col justify-center bg-[#1b1b1b] min-h-screen">
              <div className="text-right">
                <FontAwesomeIcon
                  className="text-3xl"
                  icon={faTimes}
                  onClick={toggleNav}
                />
              </div>
              <div>
                <ul className="flex flex-col justify-center items-center mt-10 space-y-6 g-5}">
                  <li>
                    {
                      // eslint-disable-next-line
                    }
                    <a href="#" className="text-2xl" onClick={toggleNav}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#about" className="text-2xl" onClick={toggleNav}>
                      About
                    </a>
                  </li>
                  <li>
                    <a href="#skills" className="text-2xl" onClick={toggleNav}>
                      Skills
                    </a>
                  </li>
                  <li>
                    <a
                      href="#projects"
                      className="text-2xl"
                      onClick={toggleNav}
                    >
                      Projects
                    </a>
                  </li>
                  <li>
                    <a href="#contact" className="text-2xl" onClick={toggleNav}>
                      Contact
                    </a>
                  </li>
                  <li>
                    <button className="bg-orange-500 text-white p-2 rounded-xl hover:bg-red-800 hover:text-white duration-200">
                      <a
                        href="https://drive.google.com/file/d/1xv_IZmq1lX_sBIsVcx_ghgS8pEfFDE70/view?usp=share_link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Resume
                      </a>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
