import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-full mb-24 lg:mb-2 p-4" id="about">
      <div className="about-heading">About</div>
      <div className="flex items-center justify-center text-center md:px-64">
        <p className="text-2xl ">
          I am Manan Sharma and currently I am pursing my B.Tech in Computer
          Engineering From{" "}
          <span className="text-orange-500 font-bold">
            Thapar Institure of Engineering and Technology, Patiala
          </span>
          . I am a skilled developer with expertise in both the{" "}
          <span className="text-orange-500 font-bold">Frontend</span> and the{" "}
          <span className="text-orange-500 font-bold">Backend</span>.
        </p>
      </div>
    </div>
  );
};

export default About;
